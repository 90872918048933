import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  create_invoice_pdf,
  create_remittance,
  delete_invoice,
  modify_invoice_mangefee,
  query_note_list,
  recover_invoice,
  send_invoice_by_email,
  send_invoice_by_manul,
  upload_invoice_appendix,
  upload_invoice_pdf,
} from '@/api/cerp-model';
import { message, Modal } from 'ant-design-vue';
import { reactive, ref } from 'vue';
import modalBox from '@/components/form-modal/modal-tools';
import table_info_modal from '@/components/info-modal/table_info_modal';
import { useRouter } from 'vue-router';
import { notification } from 'ant-design-vue/es';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const router = useRouter();

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const event_obj = mitt();
export const btn_list = [
  {
    name: '查看备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY);
      query_note_list({
        org_id: current_org?.id,
        object_id: record.id,
        note_type_flag: 'cerp.invoice',
      }).then((res: any) => {
        table_info_modal(
          t('备注'),
          t('备注记录'),
          [
            { title: t('备注'), dataIndex: 'note', width: 500 },
            { title: t('日期'), dataIndex: 'date', width: 150 },
            { title: t('创建人'), dataIndex: 'person_name', width: 150 },
          ],
          res.data,
        );
      });
    },
  },
  {
    name: '修改',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 1 || record.status === 2 || record.status === 6;
    },
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'SetrecordModel',
        type: 'update',
        obj: {
          ...record,
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
  {
    name: '预览',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'PreviewModal',
        type: 'invoice',
        obj: {
          ...record,
        },
      });
      modifyModal.visible = true;
      modalBox(modifyModal);
    },
  },
  {
    name: '生成PDF',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 1;
    },
    cb: (record: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否生成请款单PDF') + ' [' + record.invoice_no + ']',
        onOk() {
          return new Promise(resolve => {
            create_invoice_pdf({ org_id: current_org?.id, invoice_id: record.id })
              .then(() => {
                message.success(t('生成请款单PDF成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '手动上传PDF',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status !== 4;
    },
    cb: (data: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('手动上传PDF'),
        group: 'PDF上传',
        type: 'picture',
        filetype: ['pdf'],
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            let nsfile_id = null;
            if (payload && payload.length > 0) {
              nsfile_id = payload[0];
              if (payload.length > 1) {
                message.error(t('默认只能上传第一个文件'));
              }
            }
            upload_invoice_pdf({
              invoice_id: data.id,
              nsfile_id: nsfile_id,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '新建工资单',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 3;
    },
    cb: (record: any, t: any) => {
      if (record.contract__is_double_upload) {
        const modifyModal: any = reactive({
          visible: false,
          component: 'PayslipModal',
          type: '',
          obj: {
            ...record,
          },
        });
        modifyModal.visible = true;
        modifyModal.ok = (id: number) => {
          event_obj.emit('refresh');
        };
        modifyModal.cancel = () => {};
        modalBox(modifyModal);
      } else {
        notification.error({
          message: t('温馨提示'),
          description: t('双方合同没有确认上传'),
        });
      }
    },
  },
  {
    name: '新建垫付工资单',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 2 && record.invoice_type != 3 && record.invoice_type != 5;
    },
    cb: (record: any) => {
      if (record.contract__is_double_upload) {
        const modifyModal: any = reactive({
          visible: false,
          component: 'PaylaterslipModal',
          type: '',
          obj: {
            ...record,
          },
        });
        modifyModal.visible = true;
        modifyModal.ok = (id: number) => {
          event_obj.emit('refresh');
        };
        modifyModal.cancel = () => {};
        modalBox(modifyModal);
      } else {
        notification.error({
          message: t('温馨提示'),
          description: t('双方合同没有确认上传'),
        });
      }
    },
  },
  {
    name: '工时证明文件上传',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('工时证明文件上传'),
        group: '工时证明文件上传',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            if (data.appendix_ids) {
              payload.push(...data.appendix_ids.split(','));
            }
            upload_invoice_appendix({
              invoice_id: data.id,
              appendix_ids: payload,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('添加成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '手动发送',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 6;
    },
    cb: (record: any) => {
      let str: any = '';
      if (record?.appendix_json_list && record?.appendix_json_list.length > 0) {
        str = t('有工时证明文件一并发出');
      }
      Modal.confirm({
        title: '提示',
        content: t('是否手动发送') + ' [' + record.invoice_no + ']' + str,
        onOk() {
          return new Promise(resolve => {
            send_invoice_by_manul({ org_id: current_org?.id, invoice_id: record.id })
              .then(() => {
                message.success(t('发送成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '发送邮件',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 6;
    },
    cb: (record: any) => {
      let str: any = '';
      if (record?.appendix_json_list && record?.appendix_json_list.length > 0) {
        str = t('有工时证明文件一并发出');
      }
      Modal.confirm({
        title: '提示',
        content: t('是否手动发送') + ' [' + record.invoice_no + ']' + str,
        onOk() {
          return new Promise(resolve => {
            send_invoice_by_email({ org_id: current_org?.id, invoice_id: record.id })
              .then(() => {
                message.success(t('发送成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '重新发送',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 2;
    },
    cb: (record: any) => {
      let str: any = '';
      if (record?.appendix_json_list && record?.appendix_json_list.length > 0) {
        str = t('有工时证明文件一并发出');
      }
      Modal.confirm({
        title: '提示',
        content: t('是否手动发送') + ' [' + record.invoice_no + ']' + str,
        onOk() {
          return new Promise(resolve => {
            send_invoice_by_email({ org_id: current_org?.id, invoice_id: record.id })
              .then(() => {
                message.success(t('重新发送成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '新增汇款凭证项',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 2 || record.status === 7;
      // return record.status === 2 || !record.remittance_id;
    },
    cb: (record: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: t('新增汇款凭证项'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'remotemodal',
              name: 'remittance_set_id',
              label: '汇款凭证',
              label_i18n: '汇款凭证',
              placeholder_i18n: '请选择汇款凭证',
              disabled: false,
              placeholder: '请选择汇款凭证',
              datasourceType: 'remote',
              mode: 'default',
              labelKey: 'remittance_name',
              allowClear: true,
              title: t('请选择汇款凭证'),
              modalType: 'cerp-remittanceset',
            },
            {
              type: 'input',
              name: 'invoice_no',
              label: '请款单',
              label_i18n: '请款单',
              placeholder_i18n: '请款单',
              init_model: { is_remittance: true },
              disabled: true,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
            },
            {
              type: 'textarea',
              name: 'description',
              label: '描述',
              label_i18n: '描述',
              placeholder_i18n: '请填描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填描述',
            },
            {
              type: 'money',
              name: 'amount',
              label: '金额',
              label_i18n: '金额',
              placeholder_i18n: '请填总金额',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填总金额',
            },
          ],
          rules: {
            amount: [{ required: true, message: t('请填总金额') }],
          },
          model: {
            org_id: current_org?.id,
            is_active: true,
            remittance_set_id: null,
            invoice_id: record.id,
            invoice_no: record.invoice_no,
            description: null,
            amount: null,
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_remittance(payload)
              .then(() => {
                message.success(t('新增成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '管理费',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 1 || record.status === 2 || record.status === 6;
    },
    cb: (record: any) => {
      const editModal = reactive({
        visible: true,
        title: t('修改请款单管理费'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'invoice_no',
              label: '请款单号',
              label_i18n: '请款单号',
              placeholder_i18n: '请填写请款单号',
              disabled: true,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写请款单号',
            },
            {
              type: 'input',
              name: 'management_fee',
              label: '管理费',
              label_i18n: '管理费',
              placeholder_i18n: '请填写管理费',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写管理费',
            },
          ],
          rules: {
            management_fee: [{ required: true, message: t('请填写管理费') }],
          },
          model: {
            org_id: current_org?.id,
            is_active: true,
            invoice_id: record.id,
            invoice_no: record.invoice_no,
            management_fee: record.management_fee,
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            modify_invoice_mangefee(payload)
              .then(() => {
                message.success(t('修改请款单管理费成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '重新生成工资单',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 5;
    },
    cb: (record: any) => {
      if (record.contract__is_double_upload) {
        const modifyModal: any = reactive({
          visible: false,
          component: 'PayslipModal',
          type: '',
          obj: {
            ...record,
          },
        });
        modifyModal.visible = true;
        modifyModal.ok = (id: number) => {
          event_obj.emit('refresh');
        };
        modifyModal.cancel = () => {};
        modalBox(modifyModal);
      } else {
        notification.error({
          message: t('温馨提示'),
          description: t('双方合同没有确认上传'),
        });
      }
    },
  },
  // {
  //   name: '作废',
  //   type: 'dropdown',
  //   clazz: 'danger',
  //   is_show: (record: any) => {
  //     return record.status === 1 || record.status === 2 || record.status === 6;
  //   },
  //   cb: (record: any) => {
  //     Modal.confirm({
  //       title: t('提示'),
  //       content: '是否作废请款单 [' + record.invoice_no + ']',
  //       onOk() {
  //         return new Promise(resolve => {
  //           cancel_invoice({ org_id: current_org?.id, invoice_id: record.id })
  //             .then(() => {
  //               message.success('作废请款单成功');
  //               event_obj.emit('refresh');
  //             })
  //             .finally(() => {
  //               resolve(null);
  //             });
  //         });
  //       },
  //       onCancel() {},
  //     });
  //   },
  // },
  // {
  //   name: '取消作废',
  //   type: 'dropdown',
  //   clazz: 'primary',
  //   is_show: (record: any) => {
  //     return record.status === 4;
  //   },
  //   cb: (record: any) => {
  //     Modal.confirm({
  //       title: t('提示'),
  //       content: '是否取消作废请款单 [' + record.invoice_no + ']',
  //       onOk() {
  //         return new Promise(resolve => {
  //           resume_invoice({ org_id: current_org?.id, invoice_id: record.id })
  //             .then(() => {
  //               message.success('取消作废成功');
  //               event_obj.emit('refresh');
  //             })
  //             .finally(() => {
  //               resolve(null);
  //             });
  //         });
  //       },
  //       onCancel() {},
  //     });
  //   },
  // },
  {
    name: '删除',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return (
        (record.status === 1 || record.status === 2 || record.status === 6) && record.is_active
      );
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('提示'),
        content:
          t('是否删除请款单') +
          '[' +
          record.invoice_no +
          ']' +
          t('可能会删除相关联的汇款凭证项、工资单、等信息'),
        onOk() {
          return new Promise(resolve => {
            delete_invoice({ org_id: current_org?.id, invoice_id: record.id })
              .then(() => {
                message.success(t('删除成功'));
                record.is_active = false;
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '恢复',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return !record.is_active;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('提示'),
        content:
          t('是否恢复请款单') +
          '[' +
          record.invoice_no +
          ']' +
          t('其他已删除相关联的汇款凭证项、工资单、等信息不会恢复'),
        onOk() {
          return new Promise(resolve => {
            recover_invoice({ org_id: current_org?.id, invoice_id: record.id })
              .then(() => {
                message.success(t('恢复成功'));
                record.is_active = true;
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.invoice',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [6, 6],
  updateCol: [6, 6],
};

export const head_btn_list = [
  {
    name: '请款单批量导入',
    clazz: 'primary',
    cb: () => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('请款单批量导入'),
        group: '请款单Excel',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            router.push({ path: '/profile/basic-invoice/' + payload[0] });
            resolve(null);
          });
        },
      });
      modalBox(editModal);
    },
  },
];
